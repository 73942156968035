import React, { useState, useContext, Fragment } from "react"
import { Formik, Form } from "formik"
import Papa from "papaparse"

import Message from "elements/Message"
import Section from "elements/Section"
import Container from "layout/Container"
import ErrorBuilder from "./ErrorBuilder"
import FormSelect from "elements/Form/FormSelect"
import ActionButtons from "elements/ActionButtons"
import Dropzone from "elements/UploadDocumentDropzone/Dropzone"
import DocumentsCardRow from "elements/UploadDocumentDropzone/DocumentsCardRow"

import { verifyCSV } from "./services/verifyCSV"
import { parseDocument } from "./services/parseDocument"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { uploaderValidationSchema } from "./utils/uploaderValidationSchema"
import { useConfig } from "../../context/hooks/useConfig"
import { AppContext } from "../../context/AppContext"

const Validator = () => {
  const { state, dispatch } = useContext(AppContext)
  const [, setCSVHeaders] = useState([])
  const [, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [csvErrors, setErrors] = useState([])

  let config = useConfig()
  let options = Object.keys(config).map((key) => {
    return { value: key, label: key }
  })

  const handleUploadSuccess = async ({ file }) => {
    await dispatch({ type: "SAVE_DOCUMENT", payload: file })

    Papa.parse(file?.file, {
      complete: (csv) => {
        let headers = csv?.data?.[0]
        setCSVHeaders(
          headers?.map((header, index) => ({
            value: header,
            label: header,
            index,
          }))
        )
        parseDocument(csv, dispatch)
      },
    })
  }

  const handleVerifyCSV = () => {
    setLoading(true)
    verifyCSV({
      setProgress,
      type: state?.type,
      document: state?.parsedDocument,
      successCallback: (errors, duplicateOrders) => {
        setLoading(false)
        dispatch({ type: "SAVE_DUPLICATE_HEADERS", payload: duplicateOrders })
        errors?.length > 0
          ? setErrors(errors)
          : dispatch({
              type: "SHOW_MODAL",
              payload: {
                heading: "Data Successfully Verified!",
                isCard: true,
                headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
                content: (
                  <div className="has-text-centered">
                    <p className="is-size-5 mb-2">
                      No errors found in the CSV.
                    </p>
                    <button
                      className="button is-primary"
                      onClick={() => {
                        dispatch({ type: "RESET_DETAILS" })
                      }}
                    >
                      Back
                    </button>
                  </div>
                ),
              },
            })
      },
      errorCallback: (error) => {
        setLoading(false)
      },
      config,
    })
  }

  return (
    <Container isCentered desktop={8} fullhd={5}>
      <div className="mb-3">
        <Message color="primary">
          <div className="content">
            <p className="is-size-6">
              In case something goes wrong, please contact{" "}
              <a
                className="has-text-weight-bold"
                href="mailto:it@medgrocer.com"
              >
                it@medgrocer.com
              </a>{" "}
              <br />
              You may download a copy of the template{" "}
              <a
                href="/data/template-csv-uploader.csv"
                download="template-csv-uploader"
                className="mt-1"
              >
                here
              </a>
            </p>
          </div>
        </Message>
      </div>

      <Formik
        enableReinitialize
        onSubmit={handleVerifyCSV}
        initialValues={{ type: state?.type, headers: [] }}
        validationSchema={uploaderValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Section
              title="Select upload type"
              subtitle="Select which kind of data you are archiving"
              addOns={{ left: 1 }}
            >
              <FormSelect
                name={"type"}
                isRequired
                onChange={(value) => {
                  dispatch({ type: "SAVE_TYPE", payload: value })
                }}
                options={options}
                value={values?.type}
                setFieldValue={setFieldValue}
              />
            </Section>
            <Section
              title="Upload CSV"
              subtitle="Please make sure that the headers match the template"
              addOns={{ left: 2 }}
            >
              {state?.documents?.length > 0 ? (
                <Fragment>
                  <div className="mt-2 mb-2">
                    {state?.documents.map((file, index) => (
                      <DocumentsCardRow
                        key={index}
                        fileName={file.name}
                        oldFileName={file.oldname}
                        index={index}
                        handleDeleteDocument={() => {
                          setErrors([])
                          dispatch({ type: "RESET_DETAILS" })
                        }}
                        file={file}
                      />
                    ))}
                  </div>
                </Fragment>
              ) : (
                <Dropzone
                  docType={"csv"}
                  maxFileCount={1}
                  icon={"document"}
                  label={"Upload CSV"}
                  currentFilesList={state?.documents}
                  onUploadSuccess={handleUploadSuccess}
                />
              )}
            </Section>
            {csvErrors.length !== 0 && <ErrorBuilder errors={csvErrors} />}
            <div className="mt-2">
              <ActionButtons
                submit={{
                  loading,
                  label: (
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                      <span className="ml-1">Verify CSV</span>
                    </span>
                  ),
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Validator
