import { generateJWT } from "services/jwt"
import { firebaseApi } from "services/firebase/firebaseApi"

export const checkIfAlreadyUploaded = async ({
  data,
  orderType,
  setProgress,
  headerKeyIndex,
}) => {
  // create service account for firebase
  const JWT_OBJECT = await generateJWT()
  const ACCESS_TOKEN = JWT_OBJECT?.data?.access_token

  let totalVerified = 0
  let zendeskIdIndex = 3

  if (orderType === "Refill") zendeskIdIndex = 5

  let allUploadedRecords = []
  let allUniqueIds = [...new Set(data?.map((data) => data[zendeskIdIndex]))]

  let records = allUniqueIds?.map((id) => ({
    zendeskId: id,
    uniqueOrderIds: data
      ?.filter((row) => row[zendeskIdIndex] === id)
      ?.map((order) => order[headerKeyIndex]),
  }))

  for (const record of records) {
    // query orders collection
    let results = await queryDocuments({
      accessToken: ACCESS_TOKEN,
      value: record?.zendeskId,
      collectionId: "users",
      field: "zendeskId",
      hasFilter: true,
    })

    let vaccinationUid = results?.data[0]?.document?.name?.split("/")
    vaccinationUid = vaccinationUid?.[vaccinationUid?.length - 1]

    // query medicine sub-collection
    let existingOrderIds = []
    if (vaccinationUid) {
      let medicineResults = await queryDocuments({
        parent: `orders/${vaccinationUid}`,
        accessToken: ACCESS_TOKEN,
        collectionId: `medicines`,
      })

      if (medicineResults?.data?.length > 0)
        medicineResults.data.forEach((data) => {
          existingOrderIds.push(
            data?.document?.fields?.uniqueOrderId?.stringValue
          )
        })

      record.uniqueOrderIds.forEach((id) => {
        if (existingOrderIds.includes(id)) allUploadedRecords.push(id)
      })
    }

    totalVerified += 1
    setProgress((totalVerified / allUniqueIds?.length) * 100)
  }

  return allUploadedRecords
}

const queryDocuments = async ({
  field,
  value,
  parent,
  hasFilter,
  accessToken,
  collectionId,
}) => {
  let structuredQuery = {
    from: [
      {
        collectionId,
      },
    ],
  }

  if (hasFilter)
    structuredQuery.where = {
      fieldFilter: {
        field: {
          fieldPath: field,
        },
        op: "EQUAL",
        value: {
          stringValue: value,
        },
      },
    }

  let results = await firebaseApi({ accessToken }).post(
    `${parent || ""}:runQuery`,
    {
      structuredQuery: structuredQuery,
    }
  )

  return results
}
