import React from "react"
import Layout from "layout"
import Validator from "../components/Validator"

const ValidatorPage = () => {
  return (
    <Layout
      seoTitle="Validator"
      title="Data Validator"
      display={{ helpCenterBanner: false, footer: false }}
      subtitle="Validate your data before uploading it"
    >
      <Validator isPrivate />
    </Layout>
  )
}

export default ValidatorPage
