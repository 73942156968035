// parse documents for datatable
export const parseDocument = (file, dispatch) => {
  let fileData = file?.data
  let headers = fileData?.[0]
  let data = fileData?.slice(1)?.filter((data) => data?.length > 1)

  dispatch({
    type: "SAVE_PARSED",
    payload: { headers, data },
  })
}
